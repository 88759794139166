import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {JsonWrappedValue, AdminTalentLogEntryFilterData, AdminTalentFilterData, CompleteTalentProfileData, AdminTalentFeedPositionRelationData, TalentDeactivationData, AdminTalentLogEntryData, PagedData, AdminTalentCoachRequestCountsData, AdminTalentData, TalentTargetingFilterData, StudiesData, SubjectData, AdminTalentCoachCriteriaData, TalentFilterData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminTalentController
 */

@Injectable()
@ResourceParams({})
export class AdminTalentResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talents/count',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _countTalents: IResourceMethodObservableStrict<AdminTalentFilterData, null, null, JsonWrappedValue<number>> | undefined
    countTalents(requestBody: AdminTalentFilterData): Promise<JsonWrappedValue<number>> {
        if (!this._countTalents) throw new Error("resource has not been properly initialized")
        return this._countTalents(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/{talentId}/deactivate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deactivateTalent: IResourceMethodObservableStrict<TalentDeactivationData, null, {talentId: number}, null> | undefined
    deactivateTalent(requestBody: TalentDeactivationData, talentId: number): Promise<null> {
        if (!this._deactivateTalent) throw new Error("resource has not been properly initialized")
        return this._deactivateTalent(requestBody, null, {talentId: talentId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talent/profiles/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _editDetailedTalent: IResourceMethodObservableStrict<CompleteTalentProfileData, null, {id: number}, null> | undefined
    editDetailedTalent(requestBody: CompleteTalentProfileData, id: number): Promise<null> {
        if (!this._editDetailedTalent) throw new Error("resource has not been properly initialized")
        return this._editDetailedTalent(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talent/profiles/{id}/admin',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getDetailedTalentForAdmin: IResourceMethodObservableStrict<null, null, {id: number}, CompleteTalentProfileData> | undefined
    getDetailedTalentForAdmin(id: number): Promise<CompleteTalentProfileData> {
        if (!this._getDetailedTalentForAdmin) throw new Error("resource has not been properly initialized")
        return this._getDetailedTalentForAdmin(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talents/positions/{id}/filter',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _getFilteredTalentsForPosition: IResourceMethodObservableStrict<TalentFilterData[], {page: number, pageSize: number}, {id: number}, PagedData<AdminTalentFeedPositionRelationData>> | undefined
    getFilteredTalentsForPosition(requestBody: TalentFilterData[], id: number, requestParams: {page: number, pageSize: number}): Promise<PagedData<AdminTalentFeedPositionRelationData>> {
        if (!this._getFilteredTalentsForPosition) throw new Error("resource has not been properly initialized")
        return this._getFilteredTalentsForPosition(requestBody, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/talents/{id}/mostRecentStudies',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMostRecentStudiesForTalent: IResourceMethodObservableStrict<null, null, {id: number}, StudiesData> | undefined
    getMostRecentStudiesForTalent(id: number): Promise<StudiesData> {
        if (!this._getMostRecentStudiesForTalent) throw new Error("resource has not been properly initialized")
        return this._getMostRecentStudiesForTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talents/numWaitingOfAgent',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getNumTalentsWaitingForAgent: IResourceMethodObservableStrict<null, null, null, AdminTalentCoachRequestCountsData> | undefined
    getNumTalentsWaitingForAgent(): Promise<AdminTalentCoachRequestCountsData> {
        if (!this._getNumTalentsWaitingForAgent) throw new Error("resource has not been properly initialized")
        return this._getNumTalentsWaitingForAgent(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/talents/{id}/studies',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getStudiesForTalent: IResourceMethodObservableStrict<null, null, {id: number}, StudiesData[]> | undefined
    getStudiesForTalent(id: number): Promise<StudiesData[]> {
        if (!this._getStudiesForTalent) throw new Error("resource has not been properly initialized")
        return this._getStudiesForTalent(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talents/logEntries',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentLogEntries: IResourceMethodObservableStrict<AdminTalentLogEntryFilterData, null, null, AdminTalentLogEntryData[]> | undefined
    getTalentLogEntries(requestBody: AdminTalentLogEntryFilterData): Promise<AdminTalentLogEntryData[]> {
        if (!this._getTalentLogEntries) throw new Error("resource has not been properly initialized")
        return this._getTalentLogEntries(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/talents/{id}/subject',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentSubject: IResourceMethodObservableStrict<null, null, {id: number}, SubjectData> | undefined
    getTalentSubject(id: number): Promise<SubjectData> {
        if (!this._getTalentSubject) throw new Error("resource has not been properly initialized")
        return this._getTalentSubject(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalents: IResourceMethodObservableStrict<AdminTalentFilterData, {sortAsc: boolean, page?: number, pageSize?: number}, null, PagedData<AdminTalentData>> | undefined
    getTalents(requestBody: AdminTalentFilterData, requestParams: {sortAsc: boolean, page?: number, pageSize?: number}): Promise<PagedData<AdminTalentData>> {
        if (!this._getTalents) throw new Error("resource has not been properly initialized")
        return this._getTalents(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talents/targeting',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentsByPositionTargetingFilter: IResourceMethodObservableStrict<TalentTargetingFilterData, {page: number, pageSize: number}, null, PagedData<AdminTalentFeedPositionRelationData>> | undefined
    getTalentsByPositionTargetingFilter(requestBody: TalentTargetingFilterData, requestParams: {page: number, pageSize: number}): Promise<PagedData<AdminTalentFeedPositionRelationData>> {
        if (!this._getTalentsByPositionTargetingFilter) throw new Error("resource has not been properly initialized")
        return this._getTalentsByPositionTargetingFilter(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/talents',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getTalentsByQuery: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<AdminTalentData>> | undefined
    getTalentsByQuery(requestParams?: {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<AdminTalentData>> {
        if (!this._getTalentsByQuery) throw new Error("resource has not been properly initialized")
        return this._getTalentsByQuery(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talents/{id}/checked',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _markTalentAsChecked: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    markTalentAsChecked(id: number): Promise<null> {
        if (!this._markTalentAsChecked) throw new Error("resource has not been properly initialized")
        return this._markTalentAsChecked(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/talents/{id}/coachCriteria',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateTalentCoachCriteria: IResourceMethodObservableStrict<AdminTalentCoachCriteriaData, null, {id: number}, null> | undefined
    updateTalentCoachCriteria(requestBody: AdminTalentCoachCriteriaData, id: number): Promise<null> {
        if (!this._updateTalentCoachCriteria) throw new Error("resource has not been properly initialized")
        return this._updateTalentCoachCriteria(requestBody, null, {id: id}).toPromise()
    }

}